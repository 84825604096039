import React, { useEffect, useState } from "react";
import Countdown from "react-countdown";
import AccessAlarmIcon from "@mui/icons-material/AccessAlarm";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import {
  getExamById,
  getExamResultbyId,
  getExamResults,
  submitAssignmentTHPT,
} from "../services/MockExamService";
import { Button, Checkbox, MenuItem, TextField, Tooltip } from "@mui/material";
import Mathjax from "./Mathjax";
import { v4 as uuidv4 } from "uuid";
import NavbarComponent from "./NavbarComponent";
import HeroWrapComponent from "./HelloWorldComponent";
import FooterComponent from "./FooterComponent";
import SendIcon from "@mui/icons-material/Send";
import VisibilityIcon from "@mui/icons-material/Visibility";
import QuizIcon from "@mui/icons-material/Quiz";
import LoadingComponent from "./LoadingComponent";
import CameraIcon from "@mui/icons-material/Camera";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import MathRenderer from "../MathRenderer";

const ExecuteTest_THPT_Component = (props) => {
  const {} = props;
  const navigate = useNavigate();
  const [examResult, setExamResult] = useState(null);
  const [examResults, setExamResults] = useState(null);
  const [examData, setExamData] = useState(null);
  const [countdownTime, setCountdownTime] = useState(0);
  const [isCompleted, setIsCompleted] = useState(false);
  const [isStarted, setIsStarted] = useState(false);
  const { examId } = useParams();

  const [loading, setLoading] = useState(false);
  const [partIAnswer, setPartIAnswer] = useState({});
  const [partIIAnswer, setPartIIAnswer] = useState({
    "Câu 1": { a: "", b: "", c: "", d: "" },
    "Câu 2": { a: "", b: "", c: "", d: "" },
    "Câu 3": { a: "", b: "", c: "", d: "" },
    "Câu 4": { a: "", b: "", c: "", d: "" },
  });
  const [partIIIAnswer, setPartIIIAnswer] = useState({});

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const response = await getExamResultbyId(examId);
        if (
          response &&
          response.data &&
          response.data.data &&
          response.status === 200
        ) {
          let { data } = response.data;
          setExamResult(data);
          setIsStarted(data?.isStarted);
          setIsCompleted(data?.isCompleted);
        }
      } catch (error) {
        const message = error?.response?.data?.message;
        toast.error(message);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [examId, isCompleted]);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const response = await getExamResults(examId);
        if (
          response &&
          response.data &&
          response.data.data &&
          response.status === 200
        ) {
          const { data } = response.data;
          setExamResults(data);
        }
      } catch (error) {
        const message = error?.response?.data?.message;
        toast.error(message);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [examId, isCompleted]);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const response = await getExamById(examId);
        if (
          response &&
          response.data &&
          response.data.data &&
          response.status === 200
        ) {
          let { data } = response.data;
          setExamData(data);
          const savedCountdownTime = localStorage.getItem(
            `countdownTime_${examId}`
          );
          if (!savedCountdownTime) {
            setCountdownTime(parseInt(data.time * 60 * 1000));
          } else {
            setCountdownTime(parseInt(JSON.parse(savedCountdownTime)));
          }
        }
      } catch (error) {
        const message = error?.response?.data?.message;
        toast.error(message);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [examId, isCompleted]);

  useEffect(() => {
    const localPartIAnswer = localStorage.getItem(`partIAnswer_${examId}`);
    if (localPartIAnswer) {
      setPartIAnswer(JSON.parse(localPartIAnswer));
    }

    const localPartIIAnswer = localStorage.getItem(`partIIAnswer_${examId}`);
    if (localPartIIAnswer) {
      setPartIIAnswer(JSON.parse(localPartIIAnswer));
    }

    const localPartIIIAnswer = localStorage.getItem(`partIIIAnswer_${examId}`);
    if (localPartIIIAnswer) {
      setPartIIIAnswer(JSON.parse(localPartIIIAnswer));
    }
  }, []);

  const handleChangePartIAnwers = async (event) => {
    const { name, value } = event.target;

    // Cập nhật giá trị của TextField trong state answers
    setPartIAnswer((prevAnswers) => {
      const newAnswer = { ...prevAnswers, [name]: value };
      localStorage.setItem(`partIAnswer_${examId}`, JSON.stringify(newAnswer));
      return newAnswer;
    });
  };

  const handleChangePartIIAnwers = (question, option, isCorrect) => {
    setPartIIAnswer((prevAnswers) => {
      const newAnswer = {
        ...prevAnswers,
        [question]: {
          ...prevAnswers[question],
          [option]: isCorrect ? "D" : "S",
        },
      };
      localStorage.setItem(`partIIAnswer_${examId}`, JSON.stringify(newAnswer));
      return newAnswer;
    });
  };
  const handleChangePartIIIAnwers = async (event) => {
    const { name, value } = event.target;
    const numericValue = value === "0" ? "0" : parseFloat(value);
    // Cập nhật giá trị của TextField trong state answers
    setPartIIIAnswer((prevAnswers) => {
      const newAnswer = {
        ...prevAnswers,
        [name]: numericValue,
      };
      localStorage.setItem(
        `partIIIAnswer_${examId}`,
        JSON.stringify(newAnswer)
      );
      return newAnswer;
    });
  };

  const renderer = ({ hours, minutes, seconds, completed }) => {
    setCountdownTime(
      hours * 60 * 60 * 1000 + minutes * 60 * 1000 + seconds * 1000
    );
    localStorage.setItem(
      `countdownTime_${examId}`,
      JSON.stringify(
        hours * 60 * 60 * 1000 + minutes * 60 * 1000 + seconds * 1000
      )
    );
    if (completed) {
      setIsCompleted(true);
      setCountdownTime(0);
      localStorage.removeItem(`countdownTime_${examId}`);
      localStorage.removeItem(`partIAnswer_${examId}`);
      localStorage.removeItem(`partIIAnswer_${examId}`);
      localStorage.removeItem(`partIIIAnswer_${examId}`);
      handleSubmitExam();
    } else {
      return (
        <span style={{ marginLeft: "5px" }}>
          {" "}
          {hours}:{minutes}:{seconds}
        </span>
      );
    }
  };

  const handleStartExam = async () => {
    setLoading(true);
    try {
      const userAnswers = {
        partIAnswer: [],
        partIIAnswer: [],
        partIIIAnswer: [],
      };
      const results = await submitAssignmentTHPT({
        examId: examId,
        userAnswers,
      });
      if (
        results &&
        results.data &&
        results.data.data &&
        results.status === 200
      ) {
        setIsStarted(true);
        setIsCompleted(false);
        setExamResult(results.data.data);
        toast.success(results.data?.message);
      }
    } catch (error) {
      setIsStarted(false);
      const message = error?.response?.data?.message;
      toast.error(message);
    } finally {
      setLoading(false);
    }
  };
  const handleSubmitExam = async () => {
    setLoading(true);
    try {
      const userAnswers = {
        partIAnswer,
        partIIAnswer,
        partIIIAnswer,
      };

      const results = await submitAssignmentTHPT({
        examId: examId,
        userAnswers,
        examCompledTime: countdownTime,
      });

      if (results && results.data && results.status === 200) {
        localStorage.removeItem(`countdownTime_${examId}`);
        localStorage.removeItem(`partIAnswer_${examId}`);
        localStorage.removeItem(`partIIAnswer_${examId}`);
        localStorage.removeItem(`partIIIAnswer_${examId}`);
        setCountdownTime(0);
        setIsStarted(true);
        setIsCompleted(true);
        // setExamResult(results.data.data);
        toast.success(results.data?.message);
      }
    } catch (error) {
      const message = error?.response?.data?.message;
      toast.error(message);
    } finally {
      setLoading(false);
    }
  };

  const handleStartRetestExam = async () => {
    setLoading(true);
    try {
      const userAnswers = {
        partIAnswer: [],
        partIIAnswer: [],
        partIIIAnswer: [],
      };
      const results = await submitAssignmentTHPT({
        examId: examId,
        userAnswers,
        isReTest: true,
      });
      if (
        results &&
        results.data &&
        results.data.data &&
        results.status === 200
      ) {
        setIsStarted(true);
        setIsCompleted(false);
        setExamResult(results.data.data);
        toast.success(results.data?.message);
      }
    } catch (error) {
      const message = error?.response?.data?.message;
      toast.error(message);
    } finally {
      setLoading(false);
    }
  };

  const handleViewSelectedAnswerExam = () => {
    navigate(`/exam-papers/view-selected-answer-thpt/${examId}`);
  };

  if (loading) {
    return <LoadingComponent />;
  }

  return (
    <>
      <div className="max-w-[1140px] m-auto">
        <NavbarComponent />
        <HeroWrapComponent />
      </div>
      <section
        className="ftco-section ftco-no-pb ftco-no-pt test-online"
        id="chapter-section"
      >
        <div className="">
          <div className="py-5 mt-[2.25rem]">
            <div className="col-md-12 heading-section text-center">
              <h2 className="mb-4">{examData?.title}</h2>
              <h5 className="mb-4 signika">
                Thời gian: 21h đến 22h30 &#40;{examData?.time} phút&#41;
              </h5>
              <h5 className="mb-[2.25rem] signika">
                Số lượng câu hỏi: {examData?.numberOfQuestions}
              </h5>
              <a
                href={examData?.url}
                target="_blank"
                rel="noreferrer"
                className="text-emerald-600 border-2 border-dashed border-gray-400 p-[10px]"
              >
                Link đề ở đây
              </a>
            </div>
            {!isStarted && (
              <div className="flex justify-center mt-10">
                <Button
                  className=""
                  variant="contained"
                  startIcon={<CameraIcon />}
                  size="large"
                  onClick={handleStartExam}
                  disabled={examResult?.maximumNumberOfExams <= 0}
                >
                  Bắt đầu thi thử
                </Button>
              </div>
            )}
          </div>

          <div className="">
            {isStarted && !isCompleted && (
              <div className="w-full">
                <div className="mb-4 mt-5">
                  <nav id="navi" className="nav-question">
                    <div className="flex justify-center">
                      <h1 className="text-[25px] text-center mr-3">
                        Thời gian còn lại:{" "}
                      </h1>
                      <span
                        className="flex justify-center items-center text-[25px]"
                        style={{
                          color: "#dc3545",
                        }}
                      >
                        <AccessAlarmIcon />{" "}
                        <Countdown
                          date={Date.now() + countdownTime}
                          renderer={renderer}
                        />
                      </span>
                    </div>
                  </nav>
                </div>
                {examData?.questions?.questionsPHANI?.length > 0 && (
                  <div className="xl:w-[80%] w-[100%] m-auto mt-10">
                    <h1 className="text-center text-[30px]">PHẦN I</h1>
                    {/* <div id="page-1" className="page bg-light"> */}
                    {examData.questions?.questionsPHANI?.map((q, index) => {
                      const {
                        question,
                        contentQuestions,
                        contentAnswerA,
                        contentAnswerB,
                        contentAnswerC,
                        contentAnswerD,
                        imageUrl,
                      } = q;
                      return (
                        <div
                          key={index}
                          className="mx-auto relative mt-4 page md:p-[30px] p-[10px] border-2 border-dashed border-gray-400"
                        >
                          <h2 className="heading text-left text-[1.2rem] mb-2 question-label">
                            {question}:
                          </h2>
                          <div className="question-content">
                            <MathRenderer content={contentQuestions?.text} />
                          </div>
                          <div>
                            {" "}
                            {imageUrl && (
                              <img
                                src={imageUrl}
                                alt={`pimath-${uuidv4()}`}
                                className="lg:w-[35%] md:w-[40%] sm:w-[60%] w-[70%] m-auto"
                              ></img>
                            )}
                          </div>
                          <div className="grid grid-cols-2 gap-4 mt-5 text-black">
                            <div className="flex items-center">
                              <strong>A.</strong>&nbsp; &nbsp;
                              <MathRenderer content={contentAnswerA?.text} />
                            </div>
                            <div className="flex items-center">
                              <strong>B.</strong>&nbsp; &nbsp;
                              <MathRenderer content={contentAnswerB?.text} />
                            </div>
                            <div className="flex items-center">
                              <strong>C.</strong>&nbsp; &nbsp;
                              <MathRenderer content={contentAnswerC?.text} />
                            </div>
                            <div className="flex items-center">
                              <strong>D.</strong>&nbsp; &nbsp;
                              <MathRenderer content={contentAnswerD?.text} />
                            </div>
                            <div className="mt-5">
                              <TextField
                                id="outlined-select-currency"
                                select
                                label="Chọn đáp án"
                                name={question}
                                onChange={handleChangePartIAnwers}
                                value={partIAnswer[question] || ""}
                                className="w-[50%] text-center"
                                InputLabelProps={{
                                  shrink: true,
                                }}
                              >
                                {["A", "B", "C", "D"].map((value) => (
                                  <MenuItem key={value} value={value}>
                                    {value}
                                  </MenuItem>
                                ))}
                              </TextField>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                )}

                {examData?.questions?.questionsPHANII?.length > 0 && (
                  <div className="xl:w-[80%] w-[100%] m-auto mt-10">
                    <h1 className="text-center text-[30px]">PHẦN II</h1>
                    {/* <div id="page-1" className="page bg-light"> */}
                    {examData.questions?.questionsPHANII?.map((q, index) => {
                      const {
                        question,
                        contentQuestions,
                        contentYA,
                        contentYB,
                        contentYC,
                        contentYD,
                        imageUrl,
                      } = q;
                      return (
                        <div
                          key={index}
                          className="mx-auto relative mt-4 page md:p-[30px] p-[10px] border-2 border-dashed border-gray-400"
                        >
                          <h2 className="heading text-left text-[1.2rem] mb-2 question-label">
                            {question}:
                          </h2>
                          <div className="question-content">
                            <MathRenderer content={contentQuestions?.text} />
                          </div>
                          <div>
                            {" "}
                            {imageUrl && (
                              <img
                                src={imageUrl}
                                alt={`pimath-${uuidv4()}`}
                                className="lg:w-[35%] md:w-[40%] sm:w-[60%] w-[70%] m-auto"
                              ></img>
                            )}
                          </div>
                          <div className="overflow-x-auto md:max-w-[100%] max-w-[400px] m-auto">
                            <table className="table-contaiter styled-table">
                              <thead>
                                <tr>
                                  <th rowSpan="2" className="question">
                                    Mệnh đề
                                  </th>
                                  <th colSpan="2" className="text-center">
                                    Nội dung
                                  </th>
                                  <th rowSpan="2" className="correct  ">
                                    Đúng
                                  </th>
                                  <th rowSpan="2" className="wrong ">
                                    Sai
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                {["a", "b", "c", "d"].map((option, index) => {
                                  const content = [
                                    contentYA,
                                    contentYB,
                                    contentYC,
                                    contentYD,
                                  ][index];
                                  return (
                                    <tr key={option}>
                                      <td className="question">{option})</td>
                                      <td className="statement" colSpan="2">
                                        <MathRenderer content={content?.text} />
                                      </td>
                                      <td className="correct">
                                        <Checkbox
                                          onChange={() =>
                                            handleChangePartIIAnwers(
                                              question,
                                              option,
                                              true
                                            )
                                          }
                                          checked={
                                            partIIAnswer[question][option] ===
                                            "D"
                                          }
                                        />
                                      </td>
                                      <td className="wrong">
                                        <Checkbox
                                          onChange={() =>
                                            handleChangePartIIAnwers(
                                              question,
                                              option,
                                              false
                                            )
                                          }
                                          checked={
                                            partIIAnswer[question][option] ===
                                            "S"
                                          }
                                        />
                                      </td>
                                    </tr>
                                  );
                                })}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                )}

                {examData?.questions?.questionsPHANII?.length > 0 && (
                  <div className="xl:w-[80%] w-full m-auto mt-10">
                    <h1 className="text-center text-[30px]">PHẦN III</h1>
                    {examData.questions?.questionsPHANIII?.map((q, index) => {
                      const { question, contentQuestions, imageUrl } = q;
                      return (
                        <div
                          key={index}
                          className="mx-auto  relative mt-4 page md:p-[30px] p-[10px] border-2 border-dashed border-gray-400"
                        >
                          <h2 className="heading text-left text-[1.2rem] mb-2 question-label">
                            {question}:
                          </h2>
                          <div className="question-content">
                            <MathRenderer content={contentQuestions?.text} />
                          </div>
                          <div>
                            {" "}
                            {imageUrl && (
                              <img
                                src={imageUrl}
                                alt={`pimath-${uuidv4()}`}
                                className="lg:w-[35%] md:w-[40%] sm:w-[60%] w-[70%] m-auto"
                              ></img>
                            )}
                          </div>
                          <div className="grid grid-cols-2 gap-4 mt-5 text-black">
                            <div className="mt-5">
                              <TextField
                                id="number-input"
                                label="Đáp án *"
                                type="number"
                                name={question}
                                onChange={handleChangePartIIIAnwers}
                                className="text-center"
                                value={partIIIAnswer[question] || ""}
                                // onChange={handleChange}
                                InputLabelProps={{
                                  shrink: true,
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                )}

                {examData?.questions?.questionsPHANI?.length > 0 ||
                examData?.questions?.questionsPHANII?.length > 0 ||
                examData?.questions?.questionsPHANIII?.length > 0 ? (
                  <div className="w-full text-center mb-10">
                    <Button
                      variant="contained"
                      startIcon={<SendIcon />}
                      size="large"
                      onClick={handleSubmitExam}
                    >
                      Nộp bài
                    </Button>
                  </div>
                ) : (
                  ""
                )}
              </div>
            )}
            {isStarted && isCompleted && (
              <div className="w-full flex justify-center md:flex-row flex-col mb-10 mt-5">
                <Tooltip
                  title={`Số lần thi còn lại: ${examResult?.maximumNumberOfExams}`}
                  placement="top"
                >
                  <span className="md:m-0 mx-auto">
                    {" "}
                    <Button
                      className="w-[179.91px] md:mr-2 mr-0 md:mb-0 mb-5"
                      variant="contained"
                      startIcon={<QuizIcon />}
                      size="large"
                      onClick={handleStartRetestExam}
                      disabled={examResult?.maximumNumberOfExams <= 0}
                    >
                      Thi lại
                    </Button>
                  </span>
                </Tooltip>

                <span className="md:m-0 mx-auto">
                  <Button
                    variant="contained"
                    startIcon={<VisibilityIcon />}
                    className="w-[179.91px] md:ml-2 ml-0"
                    size="large"
                    onClick={handleViewSelectedAnswerExam}
                  >
                    Xem đáp án
                  </Button>
                </span>
              </div>
            )}
          </div>

          {isCompleted && (
            <>
              {examResult && (
                <div>
                  <h1 className="text-center text-[30px] mb-10">
                    KẾT QUẢ THI THỬ CỦA BẠN
                  </h1>
                  <div className="overflow-auto md:max-w-[1200px] sm:max-w-[600px] mb:max-w-[500px] max-w-[400px] m-auto">
                    <TableContainer component={Paper}>
                      <Table sx={{ minWidth: 400 }} aria-label="simple table">
                        <TableHead>
                          <TableRow>
                            <StyledTableCell align="left">
                              Thứ hạng
                            </StyledTableCell>
                            <StyledTableCell align="left">Tên</StyledTableCell>
                            <StyledTableCell align="left">
                              Trường học
                            </StyledTableCell>
                            <StyledTableCell align="left">
                              Điểm số
                            </StyledTableCell>
                            <StyledTableCell align="left">
                              Thời gian thi
                            </StyledTableCell>
                            <StyledTableCell align="left">
                              Thời gian nộp bài
                            </StyledTableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          <StyledTableRow
                            sx={{
                              "&:last-child td, &:last-child th": { border: 0 },
                            }}
                          >
                            <StyledTableCell
                              className={
                                ![1, 2, 3].includes(examResult?.ranking)
                                  ? "md:pl-[39px] pl-[34px] py-[2px] pr-[16px]"
                                  : "py-[2px] pr-[16px]"
                              }
                            >
                              {examResult?.ranking === 1 ? (
                                <img
                                  src={"/images/top1.png"}
                                  className="md:w-[55px] md:h-[55px] w-[45px] h-[45px]"
                                  alt="top1"
                                />
                              ) : examResult?.ranking === 2 ? (
                                <img
                                  src={"/images/top2.png"}
                                  className="md:w-[55px] md:h-[55px] w-[45px] h-[45px]"
                                  alt="top2"
                                />
                              ) : examResult?.ranking === 3 ? (
                                <img
                                  src={"/images/top3.png"}
                                  className="md:w-[55px] md:h-[55px] w-[45px] h-[45px]"
                                  alt="top3"
                                />
                              ) : (
                                examResult?.ranking
                              )}
                            </StyledTableCell>
                            <StyledTableCell align="left">
                              {examResult?.user?.username}
                            </StyledTableCell>
                            <StyledTableCell align="left">
                              {examResult?.user?.school}
                            </StyledTableCell>
                            <StyledTableCell align="left">
                              {examResult?.total_score}
                            </StyledTableCell>
                            <StyledTableCell align="left">
                              {examResult?.examCompledTime} phút
                            </StyledTableCell>
                            <StyledTableCell align="left">
                              {examResult?.updatedAt}
                            </StyledTableCell>
                          </StyledTableRow>
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </div>
                </div>
              )}

              {examResults?.length > 0 && (
                <div className="mt-[40px] mb-[40px]">
                  <h1 className="text-center text-[30px] mb-10">
                    KẾT QUẢ THI THỬ CHUNG
                  </h1>
                  <div className="overflow-auto md:max-w-[1200px] sm:max-w-[600px] mb:max-w-[500px] max-w-[400px] m-auto">
                    {" "}
                    <TableContainer component={Paper}>
                      <Table sx={{ minWidth: 400 }} aria-label="simple table">
                        <TableHead>
                          <TableRow>
                            <StyledTableCell align="left">
                              Thứ hạng
                            </StyledTableCell>
                            <StyledTableCell align="left">Tên</StyledTableCell>
                            <StyledTableCell align="left">
                              Trường học
                            </StyledTableCell>
                            <StyledTableCell align="left">
                              Điểm số
                            </StyledTableCell>
                            <StyledTableCell align="left">
                              Thời gian thi
                            </StyledTableCell>
                            <StyledTableCell align="left">
                              Thời gian nộp bài
                            </StyledTableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {examResults.map((item, index) => (
                            <StyledTableRow
                              sx={{
                                "&:last-child td, &:last-child th": {
                                  border: 0,
                                },
                              }}
                              key={index}
                            >
                              <StyledTableCell
                                align="left"
                                className={
                                  ![1, 2, 3].includes(item?.ranking)
                                    ? "md:pl-[39px] pl-[34px] py-[2px] pr-[16px]"
                                    : "py-[2px] pr-[16px]"
                                }
                              >
                                {item?.ranking === 1 ? (
                                  <img
                                    src={"/images/top1.png"}
                                    className="md:w-[55px] md:h-[55px] w-[45px] h-[45px]"
                                    alt="top1"
                                  />
                                ) : item?.ranking === 2 ? (
                                  <img
                                    src={"/images/top2.png"}
                                    className="md:w-[55px] md:h-[55px] w-[45px] h-[45px]"
                                    alt="top2"
                                  />
                                ) : item?.ranking === 3 ? (
                                  <img
                                    src={"/images/top3.png"}
                                    className="md:w-[55px] md:h-[55px] w-[45px] h-[45px]"
                                    alt="top3"
                                  />
                                ) : (
                                  item?.ranking
                                )}
                              </StyledTableCell>
                              <StyledTableCell align="left">
                                {item?.user?.username}
                              </StyledTableCell>
                              <StyledTableCell align="left">
                                {item?.user?.school}
                              </StyledTableCell>
                              <StyledTableCell align="left">
                                {item?.total_score}
                              </StyledTableCell>
                              <StyledTableCell align="left">
                                {item?.examCompledTime} phút
                              </StyledTableCell>
                              <StyledTableCell align="left">
                                {item?.updatedAt}
                              </StyledTableCell>
                            </StyledTableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </div>
                </div>
              )}
            </>
          )}
        </div>
      </section>
      <FooterComponent />
    </>
  );
};

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#009879",
    color: "#ffffff !important",
    fontFamily: "'Potta One', system-ui !important",
    fontSize: "17px !important",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

export default ExecuteTest_THPT_Component;
