import { Box } from "@mui/material";
import React from "react";

function LoadingComponent() {
  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      className="py-[35px]"
      marginBottom="40px"
      // height="100vh"
      // bgcolor="#98cfb2"
    >
      <div className="loader">
        <svg viewBox="0 0 80 80">
          <circle id="test" cx="40" cy="40" r="32"></circle>
        </svg>
      </div>

      <div className="loader triangle">
        <svg viewBox="0 0 86 80">
          <polygon points="43 8 79 72 7 72"></polygon>
        </svg>
      </div>

      <div className="loader">
        <svg viewBox="0 0 80 80">
          <rect x="8" y="8" width="64" height="64"></rect>
        </svg>
      </div>
      <a
        className="dribbble"
        href="https://dribbble.com/shots/5878367-Loaders"
        target="_blank"
        rel="noreferrer"
      >
        {/* <img
            src="https://cdn.dribbble.com/assets/dribbble-ball-mark-2bd45f09c2fb58dbbfb44766d5d1d07c5a12972d602ef8b32204d28fa3dda554.svg"
            alt="pimath"
          /> */}
      </a>
    </Box>
  );
}

export default LoadingComponent;
