import React, { useEffect } from "react";
import NavbarComponent from "./NavbarComponent";
import HeroWrapComponent from "./HelloWorldComponent";
import AboutSectionComponent from "./AboutSectionComponent";
import ContactSectionComponent from "./ContactSectionComponent";
import FooterComponent from "./FooterComponent";
import CourseIntroductionComponent from "./CourseIntroductionComponent";
import CounterSectionComponent from "./CounterSectionComponent";
import CourseReviewSectionComponent from "./CourseReviewSectionComponent";

const HomeComponent = (props) => {
  return (
    <div>
      <div className="max-w-[1140px] m-auto ">
        <NavbarComponent />
        <HeroWrapComponent />

        <AboutSectionComponent />
        <CounterSectionComponent />
        <CourseIntroductionComponent />
      </div>
      {/* <CourseReviewSectionComponent /> */}
      <div className="max-w-[1140px] m-auto ">
        {/* <ProjectsSectionComponent /> */}
        {/* <AuthorSectionComponent /> */}
        <ContactSectionComponent />
      </div>
      <FooterComponent />
    </div>
  );
};

HomeComponent.propTypes = {};

export default HomeComponent;
