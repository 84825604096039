import { Checkbox, MenuItem, TextField } from "@mui/material";
import { v4 as uuidv4 } from "uuid";
import MathRenderer from "../MathRenderer";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

const QuestionList = ({
  questions,
  userAnswers,
  handleChangeUserAnswersTN,
  handleChangeUserAnswersDS,
  handleChangeUserAnswersTLN,
  handleChangeUserAnswersTLN_M,
  onDragEnd,
}) => {
  if (!questions || questions.length === 0) {
    return;
  }

  return (
    <div className="xl:w-[80%] w-[100%] m-auto mt-10">
      {questions?.map((q, index) => {
        const { question, contentQuestions, type, imageUrl } = q;
        if (type === "TN") {
          return (
            <div
              key={index}
              className="mx-auto relative mt-4 page md:p-[30px] p-[10px] border-2 border-dashed border-gray-400"
            >
              <h2 className="heading text-left text-[1.2rem] mb-2 question-label">
                {question}:
              </h2>
              <span className="question-content">
                <MathRenderer content={contentQuestions} />
              </span>
              <div>
                {" "}
                {imageUrl && (
                  <img
                    src={imageUrl}
                    alt={`pimath-${uuidv4()}`}
                    className="lg:w-[35%] md:w-[40%] sm:w-[60%] w-[70%] m-auto"
                  ></img>
                )}
              </div>
              <div className="grid grid-cols-2 gap-4 mt-5 text-black">
                <div className="flex items-center">
                  <strong>A.</strong>&nbsp; &nbsp;
                  <MathRenderer content={q?.contentAnswerA} />
                </div>
                <div className="flex items-center">
                  <strong>B.</strong>&nbsp; &nbsp;
                  <MathRenderer content={q?.contentAnswerB} />
                </div>
                <div className="flex items-center">
                  <strong>C.</strong>&nbsp; &nbsp;
                  <MathRenderer content={q?.contentAnswerC} />
                </div>
                <div className="flex items-center">
                  <strong>D.</strong>&nbsp; &nbsp;
                  <MathRenderer content={q?.contentAnswerD} />
                </div>
                <div className="mt-5">
                  <TextField
                    id="outlined-select-currency"
                    select
                    label="Chọn đáp án"
                    name={question}
                    onChange={handleChangeUserAnswersTN}
                    value={userAnswers[question] || ""}
                    className="md:w-[50%] w-[100%] text-center"
                    InputLabelProps={{
                      shrink: true,
                    }}
                  >
                    {["A", "B", "C", "D"].map((value) => (
                      <MenuItem key={value} value={value}>
                        {value}
                      </MenuItem>
                    ))}
                  </TextField>
                </div>
              </div>
            </div>
          );
        }

        if (type === "DS") {
          return (
            <div
              key={index}
              className="mx-auto relative mt-4 page md:p-[30px] p-[10px] border-2 border-dashed border-gray-400"
            >
              <h2 className="heading text-left text-[1.2rem] mb-2 question-label">
                {question}:
              </h2>
              <div className="question-content">
                <MathRenderer content={contentQuestions} />
              </div>
              <div>
                {" "}
                {imageUrl && (
                  <img
                    src={imageUrl}
                    alt={`pimath-${uuidv4()}`}
                    className="lg:w-[35%] md:w-[40%] sm:w-[60%] w-[70%] m-auto"
                  ></img>
                )}
              </div>
              <div className="overflow-x-auto md:max-w-[100%] max-w-[400px] m-auto">
                <table className="table-contaiter styled-table">
                  <thead>
                    <tr>
                      <th rowSpan="2" className="question">
                        Mệnh đề
                      </th>
                      <th colSpan="2" className="text-center">
                        Nội dung
                      </th>
                      <th rowSpan="2" className="correct  ">
                        Đúng
                      </th>
                      <th rowSpan="2" className="wrong ">
                        Sai
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {["a", "b", "c", "d"].map((option, index) => {
                      const content = [
                        q?.contentYA,
                        q?.contentYB,
                        q?.contentYC,
                        q?.contentYD,
                      ][index];
                      if (content) {
                        return (
                          <tr key={option}>
                            <td className="question">{option})</td>
                            <td className="statement" colSpan="2">
                              <MathRenderer content={content} />
                            </td>
                            <td className="correct">
                              <Checkbox
                                onChange={() =>
                                  handleChangeUserAnswersDS(
                                    question,
                                    option,
                                    true
                                  )
                                }
                                checked={userAnswers[question][option] === "D"}
                              />
                            </td>
                            <td className="wrong">
                              <Checkbox
                                onChange={() =>
                                  handleChangeUserAnswersDS(
                                    question,
                                    option,
                                    false
                                  )
                                }
                                checked={userAnswers[question][option] === "S"}
                              />
                            </td>
                          </tr>
                        );
                      }
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          );
        }

        if (type === "KT") {
          return (
            <div
              key={index}
              className="mx-auto  relative mt-4 page md:p-[30px] p-[10px] border-2 border-dashed border-gray-400"
            >
              <h2 className="heading text-left text-[1.2rem] mb-2 question-label">
                {question}:
              </h2>
              <div className="question-content">
                <MathRenderer content={q?.contentQuestions} />
              </div>
              <div>
                {" "}
                {imageUrl && (
                  <img
                    src={imageUrl}
                    alt={`pimath-${uuidv4()}`}
                    className="lg:w-[35%] md:w-[40%] sm:w-[60%] w-[70%] m-auto"
                  ></img>
                )}
              </div>
              <div>
                <DragDropContext onDragEnd={onDragEnd}>
                  <div className="container mx-auto p-6">
                    <div key={q.question} className="mb-8">
                      <Droppable droppableId={q.question}>
                        {(provided) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.droppableProps}
                            className="p-4 rounded mt-2 min-h-[50px] border-2 border-dashed border-gray-300"
                          >
                            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
                              {q?.items?.map((item, index) => (
                                <Draggable
                                  key={item.id}
                                  draggableId={item.id}
                                  index={index}
                                >
                                  {(provided) => (
                                    <div
                                      ref={provided.innerRef}
                                      {...provided.draggableProps}
                                      {...provided.dragHandleProps}
                                      className="bg-blue-500 text-white p-4 rounded mb-2 text-center"
                                    >
                                      <MathRenderer content={item.content} />
                                    </div>
                                  )}
                                </Draggable>
                              ))}
                            </div>
                            {provided.placeholder}
                          </div>
                        )}
                      </Droppable>

                      <div className="mt-4">
                        {q?.contentY1 && (
                          <div className="mb-4">
                            <div className="flex">
                              <strong>1) </strong>&nbsp; &nbsp;
                              <MathRenderer content={q.contentY1} />
                            </div>
                            <Droppable droppableId="slot1">
                              {(provided) => (
                                <div
                                  ref={provided.innerRef}
                                  {...provided.droppableProps}
                                  className="p-4 rounded mt-2 min-h-[50px] border-2 border-dashed border-gray-300"
                                >
                                  {q?.answers?.slot1 ? (
                                    <div className="bg-blue-500 text-white p-4 rounded w-[25%] text-center">
                                      <MathRenderer
                                        content={q?.answers?.slot1?.content}
                                      />
                                    </div>
                                  ) : (
                                    <p>Thả đáp án vào đây</p>
                                  )}
                                  {provided.placeholder}
                                </div>
                              )}
                            </Droppable>
                          </div>
                        )}
                        {q?.contentY2 && (
                          <div>
                            <div className="flex">
                              <strong>2) </strong>&nbsp; &nbsp;
                              <MathRenderer content={q.contentY2} />{" "}
                            </div>

                            <Droppable droppableId="slot2">
                              {(provided) => (
                                <div
                                  ref={provided.innerRef}
                                  {...provided.droppableProps}
                                  className="p-4 rounded mt-2 min-h-[50px] border-2 border-dashed border-gray-300"
                                >
                                  {q?.answers?.slot2 ? (
                                    <div className="bg-blue-500 text-white p-4 rounded w-[25%] text-center">
                                      <MathRenderer
                                        content={q?.answers?.slot2?.content}
                                      />
                                    </div>
                                  ) : (
                                    <p>Thả đáp án vào đây</p>
                                  )}
                                  {provided.placeholder}
                                </div>
                              )}
                            </Droppable>
                          </div>
                        )}

                        {q?.contentY3 && (
                          <div>
                            <div>
                              <div className="flex">
                                <strong>3) </strong>&nbsp; &nbsp;
                                <MathRenderer content={q.contentY3} />
                              </div>
                            </div>
                            <Droppable droppableId="slot3">
                              {(provided) => (
                                <div
                                  ref={provided.innerRef}
                                  {...provided.droppableProps}
                                  className="p-4 rounded mt-2 min-h-[50px] border-2 border-dashed border-gray-300"
                                >
                                  {q?.answers?.slot3 ? (
                                    <div className="bg-blue-500 text-white p-4 rounded w-[25%] text-center">
                                      <MathRenderer
                                        content={q?.answers?.slot3?.content}
                                      />
                                    </div>
                                  ) : (
                                    <p>Thả đáp án vào đây</p>
                                  )}
                                  {provided.placeholder}
                                </div>
                              )}
                            </Droppable>
                          </div>
                        )}

                        {q?.contentY4 && (
                          <div>
                            <div className="flex">
                              <strong>4) </strong>&nbsp; &nbsp;
                              <MathRenderer content={q.contentY4} />
                            </div>
                            <Droppable droppableId="slot4">
                              {(provided) => (
                                <div
                                  ref={provided.innerRef}
                                  {...provided.droppableProps}
                                  className="p-4 rounded mt-2 min-h-[50px] border-2 border-dashed border-gray-300"
                                >
                                  {q?.answers?.slot4 ? (
                                    <div className="bg-blue-500 text-white p-4 rounded w-[25%] text-center">
                                      <MathRenderer
                                        content={q?.answers?.slot4?.content}
                                      />
                                    </div>
                                  ) : (
                                    <p>Thả đáp án vào đây</p>
                                  )}
                                  {provided.placeholder}
                                </div>
                              )}
                            </Droppable>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </DragDropContext>
              </div>
            </div>
          );
        }

        if (type === "TLN") {
          return (
            <div
              key={index}
              className="mx-auto  relative mt-4 page md:p-[30px] p-[10px] border-2 border-dashed border-gray-400"
            >
              <h2 className="heading text-left text-[1.2rem] mb-2 question-label">
                {question}:
              </h2>
              <div className="question-content">
                <MathRenderer content={q?.contentQuestions} />
              </div>
              <div>
                {" "}
                {imageUrl && (
                  <img
                    src={imageUrl}
                    alt={`pimath-${uuidv4()}`}
                    className="lg:w-[35%] md:w-[40%] sm:w-[60%] w-[70%] m-auto"
                  ></img>
                )}
              </div>
              <div className="grid grid-cols-2 gap-4 mt-5 text-black">
                <div className="mt-5">
                  <TextField
                    id="number-input"
                    label="Đáp án *"
                    type="number"
                    name={question}
                    onChange={handleChangeUserAnswersTLN}
                    className="text-center"
                    value={userAnswers[question] || ""}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </div>
              </div>
            </div>
          );
        }

        if (type === "TLN_M") {
          return (
            <div
              key={index}
              className="mx-auto  relative mt-4 page md:p-[30px] p-[10px] border-2 border-dashed border-gray-400"
            >
              <h2 className="heading text-left text-[1.2rem] mb-2 question-label">
                {question}:
              </h2>
              <div className="question-content">
                <MathRenderer content={q?.contentQuestions} />
              </div>
              <div>
                {" "}
                {imageUrl && (
                  <img
                    src={imageUrl}
                    alt={`pimath-${uuidv4()}`}
                    className="lg:w-[35%] md:w-[40%] sm:w-[60%] w-[70%] m-auto"
                  ></img>
                )}
              </div>
              {q?.contentY1 && (
                <div className="mt-4 mb-4">
                  <div className="flex">
                    <strong>1.</strong>&nbsp; &nbsp;
                    <MathRenderer content={q?.contentY1} />
                  </div>

                  <div className="mt-5">
                    <TextField
                      id="number-input"
                      label="Đáp án *"
                      type="number"
                      name={question + "-1."}
                      onChange={handleChangeUserAnswersTLN_M}
                      className="text-center"
                      value={userAnswers[question]["1."] || ""}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </div>
                </div>
              )}
              {q?.contentY2 && (
                <div className="mb-4">
                  <div className="flex">
                    <strong>2.</strong>&nbsp; &nbsp;
                    <MathRenderer content={q?.contentY2} />
                  </div>

                  <div className="mt-5">
                    <TextField
                      id="number-input"
                      label="Đáp án *"
                      type="number"
                      name={question + "-2."}
                      onChange={handleChangeUserAnswersTLN_M}
                      className="text-center"
                      value={userAnswers[question]["2."] || ""}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </div>
                </div>
              )}
              {q?.contentY3 && (
                <div className="mb-4">
                  <div className="flex">
                    <strong>3.</strong>&nbsp; &nbsp;
                    <MathRenderer content={q?.contentY3} />
                  </div>

                  <div className="mt-5">
                    <TextField
                      id="number-input"
                      label="Đáp án *"
                      type="number"
                      name={question + "-3."}
                      onChange={handleChangeUserAnswersTLN_M}
                      className="text-center"
                      value={userAnswers[question]["3."] || ""}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </div>
                </div>
              )}
              {q?.contentY4 && (
                <div className="">
                  <div className="flex">
                    <strong>4.</strong>&nbsp; &nbsp;
                    <MathRenderer content={q?.contentY4} />
                  </div>
                  <div className="mt-5">
                    <TextField
                      id="number-input"
                      label="Đáp án *"
                      type="number"
                      name={question + "-4."}
                      onChange={handleChangeUserAnswersTLN_M}
                      className="text-center"
                      value={userAnswers[question]["4."] || ""}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </div>
                </div>
              )}
            </div>
          );
        }
      })}
    </div>
  );
};

export default QuestionList;
