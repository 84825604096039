import React from "react";
import AddLocationAltIcon from "@mui/icons-material/AddLocationAlt";
import PhoneIcon from "@mui/icons-material/Phone";
import EmailIcon from "@mui/icons-material/Email";
import GroupIcon from "@mui/icons-material/Group";

const ContactSectionComponent = (props) => {
  const {} = props;

  return (
    <section
      className="ftco-section contact-section ftco-no-pb"
      id="contact-section"
    >
      <div className="container">
        <div className="row justify-content-center mb-5 pb-3">
          <div className="col-md-7 heading-section text-center ftco-animate">
            <h2 className="mb-4 pacifico">Liên hệ</h2>
            <p>
              Hãy liên hệ cho adminh Pimath nếu bạn có thắc mắc gì về khóa học.
            </p>
          </div>
        </div>
        <div className="row d-flex contact-info mb-5">
          <div className="col-md-6 col-lg-3 d-flex ftco-animate ">
            <div className="align-self-stretch box text-center p-4 bg-white">
              <div className="icon d-flex align-items-center justify-content-center text-white">
                <AddLocationAltIcon className="text-[30px]" />
              </div>
              <div>
                <p>
                  {" "}
                  <a href="#pi" className="text-[17px]">
                    Đà Nẵng
                  </a>
                </p>
              </div>
            </div>
          </div>
          <div className="col-md-6 col-lg-3 d-flex ftco-animate bg-white">
            <div className="align-self-stretch box text-center p-4 bg-white">
              <div className="icon d-flex align-items-center justify-content-center text-white">
                <PhoneIcon className="text-[30px]" />
              </div>
              <div>
                <p>
                  <a href="tel://0777561933" className="text-[17px]">
                    0777561933
                  </a>
                </p>
              </div>
            </div>
          </div>
          <div className="col-md-6 col-lg-3 d-flex ftco-animate">
            <div className="align-self-stretch box text-center p-4 bg-white">
              <div className="icon d-flex align-items-center justify-content-center text-white">
                <EmailIcon className="text-[30px]" />
              </div>
              <div>
                <p>
                  <a
                    href="mailto:tuanpham31798@gmail.com"
                    className="text-[17px]"
                  >
                    tuanpham31798@gmail.com
                  </a>
                </p>
              </div>
            </div>
          </div>
          <div className="col-md-6 col-lg-3 d-flex ftco-animate">
            <div className="align-self-stretch box text-center p-4 bg-white">
              <div className="icon d-flex align-items-center justify-content-center text-white">
                <GroupIcon className="text-[30px]" />
              </div>
              <div>
                <p>
                  <a
                    href="https://www.facebook.com/groups/pimath"
                    className="text-[17px]"
                  >
                    Nhóm Pimath
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ContactSectionComponent;
