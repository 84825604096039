import React from "react";
import LoadingComponent from "./LoadingComponent";
import QuestionList from "./QuestionList";
import ExamActions from "./ExamActions";
import Countdown from "react-countdown";
import { Button } from "@mui/material";
import AccessAlarmIcon from "@mui/icons-material/AccessAlarm";
import SendIcon from "@mui/icons-material/Send";
import ExamResult from "./ExamResult";
import CircularProgress from "@mui/material/CircularProgress";

const ExamContainer = ({
  isStarted,
  isCompleted,
  questions,
  userAnswers,
  handleChangeUserAnswersTN,
  handleChangeUserAnswersDS,
  handleChangeUserAnswersTLN,
  handleChangeUserAnswersTLN_M,
  onDragEnd,
  countdownTime,
  renderer,
  handleSubmitExam,
  examResult,
  handleStartRetestExam,
  handleViewSelectedAnswerExam,
  examResults,
}) => {
  return (
    <>
      <div>
        {isStarted && !isCompleted && (
          <div className="w-full">
            <div className="mb-4 mt-5">
              <nav id="navi" className="nav-question">
                <div className="flex justify-center">
                  <h5 className="text-[25px] text-center mr-3">
                    Thời gian còn lại:
                  </h5>
                  <span
                    className="flex justify-center items-center text-[25px]"
                    style={{
                      color: "#dc3545",
                    }}
                  >
                    <AccessAlarmIcon />{" "}
                    <Countdown
                      date={Date.now() + countdownTime}
                      renderer={renderer}
                    />
                  </span>
                </div>
              </nav>
            </div>

            <QuestionList
              questions={questions}
              userAnswers={userAnswers}
              handleChangeUserAnswersTN={handleChangeUserAnswersTN}
              handleChangeUserAnswersDS={handleChangeUserAnswersDS}
              handleChangeUserAnswersTLN={handleChangeUserAnswersTLN}
              handleChangeUserAnswersTLN_M={handleChangeUserAnswersTLN_M}
              onDragEnd={onDragEnd}
            />

            {questions?.length > 0 ? (
              <div className="w-full text-center mb-10">
                <Button
                  variant="contained"
                  startIcon={<SendIcon />}
                  size="large"
                  onClick={handleSubmitExam}
                >
                  Nộp bài
                </Button>
              </div>
            ) : (
              <CircularProgress className="flex m-auto mb-10" />
            )}
          </div>
        )}

        {isStarted && isCompleted && (
          <ExamActions
            examResult={examResult}
            handleStartRetestExam={handleStartRetestExam}
            handleViewSelectedAnswerExam={handleViewSelectedAnswerExam}
          />
        )}
        <ExamResult
          isCompleted={isCompleted}
          examResult={examResult}
          examResults={examResults}
        />
      </div>
    </>
  );
};

export default ExamContainer;
