import { useCallback } from "react";
import * as React from "react";
import Particles from "react-tsparticles";
import { loadSlim } from "tsparticles-slim"; // if you are going to use `loadSlim`, install the "tsparticles-slim" package too.
import { getUserInfoById } from "./services/UserService";
const ParticleBackground = () => {
  const particlesInit = useCallback(async (engine) => {
    await loadSlim(engine);
  }, []);
  const [loading, setLoading] = React.useState(false);

  const [user, setUser] = React.useState(null);

  React.useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const response = await getUserInfoById();
        if (
          response &&
          response.data &&
          response.data.data &&
          response.status === 200
        ) {
          const { data } = response.data;
          setUser(data);
        }
      } catch (error) {
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const particlesLoaded = useCallback(async (container) => {}, []);

  if(loading) return;
  
  return (
    (user?.email !== "ctu53922@gmail.com" || !user) && (
      <Particles
        id="tsparticles"
        init={particlesInit}
        loaded={particlesLoaded}
        options={{
          fpsLimit: 120,
          interactivity: {
            events: {
              onClick: {
                enable: true,
                mode: "push",
              },
              onHover: {
                enable: true,
                mode: "repulse",
              },
              resize: true,
            },
            modes: {
              push: {
                quantity: 2, // Giảm số lượng khi click
              },
              repulse: {
                distance: 200,
                duration: 0.4,
              },
            },
          },
          particles: {
            color: {
              value: "#17b978",
            },
            links: {
              color: "#17b978",
              distance: 150,
              enable: true,
              opacity: 0.5,
              width: 1,
            },
            move: {
              direction: "none",
              enable: true,
              outModes: {
                default: "bounce",
              },
              random: false,
              speed: 3, // Giảm tốc độ di chuyển
              straight: false,
            },
            number: {
              density: {
                enable: true,
                area: 800,
              },
              value: 30, // Giảm số lượng phần tử
            },
            opacity: {
              value: 0.5,
            },
            shape: {
              type: "circle",
            },
            size: {
              value: { min: 1, max: 3 }, // Giảm kích thước của phần tử
            },
          },
          detectRetina: true,
        }}
      />
    )
  );
};

export default ParticleBackground;
