import React, { useEffect, useState } from "react";
import NavbarComponent from "./NavbarComponent";
import HeroWrapComponent from "./HelloWorldComponent";
import FooterComponent from "./FooterComponent";
import { toast } from "react-toastify";
import LoadingComponent from "./LoadingComponent";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { getRankUserById, getRankUsers } from "../services/UserService";

function ViewRankUsers() {
  const [loading, setLoading] = useState(false);
  const [usersRanks, setUsersRanks] = useState(null);
  const [userRank, setUserRank] = useState(null);
  const currentDate = new Date();
  const currentMonth = currentDate.getMonth() + 1;

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const response = await getRankUsers();

        if (
          response &&
          response.data &&
          response.data.data &&
          response.status === 200
        ) {
          let { data } = response.data;
          setUsersRanks(data);
        }
      } catch (error) {
        const message = error?.response?.data?.message;
        toast.error(message);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const response = await getRankUserById();

        if (
          response &&
          response.data &&
          response.data.data &&
          response.status === 200
        ) {
          let { data } = response.data;
          setUserRank(data);
        }
      } catch (error) {
        const message = error?.response?.data?.message;
        toast.error(message);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  if (loading) {
    return <LoadingComponent />;
  }
  return (
    <div>
      <div className="max-w-[1140px] m-auto">
        <NavbarComponent />
        <HeroWrapComponent />
      </div>
      <h1 className="text-center text-[30px] mb-10 mt-10">
        BẢNG XẾP HẠNG TOP 10 ĐIỂM CAO NHẤT TRONG THÁNG {currentMonth}
      </h1>
      {userRank && (
        <div className="mt-[40px] mb-[40px]">
          <div className="overflow-auto md:max-w-[1200px] sm:max-w-[600px] mb:max-w-[500px] max-w-[400px] m-auto">
            {" "}
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 400 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <StyledTableCell align="left">Thứ hạng</StyledTableCell>
                    <StyledTableCell align="left">Tên</StyledTableCell>
                    <StyledTableCell align="left">Trường học</StyledTableCell>
                    <StyledTableCell align="left">Kinh nghiệm</StyledTableCell>
                    <StyledTableCell align="left">Điểm</StyledTableCell>
                    <StyledTableCell align="left">Rank</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <StyledTableRow
                    sx={{
                      "&:last-child td, &:last-child th": {
                        border: 0,
                      },
                    }}
                  >
                    <StyledTableCell
                      align="left"
                      className={
                        ![1, 2, 3].includes(userRank?.ranking)
                          ? "md:pl-[39px] pl-[34px] py-[2px] pr-[16px]"
                          : "py-[2px] pr-[16px]"
                      }
                    >
                      {userRank?.ranking === 1 ? (
                        <img
                          src={"/images/top1.png"}
                          className="md:w-[55px] md:h-[55px] w-[45px] h-[45px]"
                          alt="top1"
                        />
                      ) : userRank?.ranking === 2 ? (
                        <img
                          src={"/images/top2.png"}
                          className="md:w-[55px] md:h-[55px] w-[45px] h-[45px]"
                          alt="top2"
                        />
                      ) : userRank?.ranking === 3 ? (
                        <img
                          src={"/images/top3.png"}
                          className="md:w-[55px] md:h-[55px] w-[45px] h-[45px]"
                          alt="top3"
                        />
                      ) : (
                        userRank?.ranking
                      )}
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      {userRank?.username}
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      {userRank?.school}
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      {userRank?.level?.exp}
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      {userRank?.level?.score}
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      {userRank?.level?.rank}
                    </StyledTableCell>
                  </StyledTableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        </div>
      )}
      {usersRanks?.length > 0 && (
        <div className="mt-[40px] mb-[40px]">
          <div className="overflow-auto md:max-w-[1200px] sm:max-w-[600px] mb:max-w-[500px] max-w-[400px] m-auto">
            {" "}
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 400 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <StyledTableCell align="left">Thứ hạng</StyledTableCell>
                    <StyledTableCell align="left">Tên</StyledTableCell>
                    <StyledTableCell align="left">Trường học</StyledTableCell>
                    <StyledTableCell align="left">Kinh nghiệm</StyledTableCell>
                    <StyledTableCell align="left">Điểm</StyledTableCell>
                    <StyledTableCell align="left">Rank</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {usersRanks.map((item, index) => (
                    <StyledTableRow
                      sx={{
                        "&:last-child td, &:last-child th": {
                          border: 0,
                        },
                      }}
                      key={index}
                    >
                      <StyledTableCell
                        align="left"
                        className={
                          ![1, 2, 3].includes(item?.ranking)
                            ? "md:pl-[39px] pl-[34px] py-[2px] pr-[16px]"
                            : "py-[2px] pr-[16px]"
                        }
                      >
                        {item?.ranking === 1 ? (
                          <img
                            src={"/images/top1.png"}
                            className="md:w-[55px] md:h-[55px] w-[45px] h-[45px]"
                            alt="top1"
                          />
                        ) : item?.ranking === 2 ? (
                          <img
                            src={"/images/top2.png"}
                            className="md:w-[55px] md:h-[55px] w-[45px] h-[45px]"
                            alt="top2"
                          />
                        ) : item?.ranking === 3 ? (
                          <img
                            src={"/images/top3.png"}
                            className="md:w-[55px] md:h-[55px] w-[45px] h-[45px]"
                            alt="top3"
                          />
                        ) : (
                          item?.ranking
                        )}
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        {item?.username}
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        {item?.school}
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        {item?.level?.exp}
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        {item?.level?.score}
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        {item?.level?.rank}
                      </StyledTableCell>
                    </StyledTableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        </div>
      )}
      <FooterComponent />
    </div>
  );
}

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#009879",
    color: "#ffffff !important",
    fontFamily: "'Potta One', system-ui !important",
    fontSize: "17px !important",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

export default ViewRankUsers;
