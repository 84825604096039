import React from "react";
import katex from "katex";
import "katex/dist/katex.min.css";

const MathRenderer = ({ content }) => {
  const parseContent = (text) => {
    if (!text) return [];
    text = text.replaceAll("\\[", "$");
    text = text.replaceAll("\\]", "$");
    const regex = /\$(.*?)\$/g; // Tìm tất cả các công thức được bao bởi $...$
    const parts = [];
    let lastIndex = 0;
    let match;
    while ((match = regex.exec(text)) !== null) {
      // Đẩy phần text trước công thức vào mảng parts
      if (match.index > lastIndex) {
        parts.push(text.substring(lastIndex, match.index));
      }

      // Lấy công thức toán học từ match và loại bỏ dấu $
      const formula = match[1];

      // Render công thức bằng KaTeX và đẩy vào mảng parts
      const html = katex.renderToString(formula, {
        throwOnError: false,
      });
      parts.push(<span dangerouslySetInnerHTML={{ __html: html }} />);

      lastIndex = regex.lastIndex;
    }

    // Đẩy phần text cuối cùng (sau công thức cuối cùng) vào mảng parts
    if (lastIndex < text?.length) {
      parts.push(text.substring(lastIndex));
    }

    return parts;
  };

  return (
    <div>
      {parseContent(content ? content : "")?.map((part, index) => (
        <React.Fragment key={index}>{part}</React.Fragment>
      ))}
    </div>
  );
};

export default MathRenderer;
