import axios from "axios";
import { authHeader } from "./AuthService";

// const API_URL_AUTH = "http://localhost:4000/api/user";
const API_URL_AUTH = "https://pimath-server-edu.vercel.app/api/user";

const getRankUsers = async () => {
  return await axios.get(API_URL_AUTH + "/rank-users", {
    headers: authHeader(),
  });
};

const resetRankUsers = async () => {
  return await axios.put(API_URL_AUTH + `/rank-users`, {
    headers: authHeader(),
  });
};

const getRankUserById = async () => {
  return await axios.get(API_URL_AUTH + `/rank-user-id`, {
    headers: authHeader(),
  });
};

const updatePassword = async (body) => {
  return await axios.put(API_URL_AUTH + `/update-password`, body, {
    headers: authHeader(),
  });
};

const getUserInfoById = async () => {
  return await axios.get(API_URL_AUTH + `/info`, {
    headers: authHeader(),
  });
};
export {
  resetRankUsers,
  getRankUsers,
  getRankUserById,
  updatePassword,
  getUserInfoById,
};
