import "./App.css";
import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider,
} from "react-router-dom";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import FallingLeaves from "./FallingLeaves";
import PrivateRoute from "./routes/PrivateRoute";
import PublicRoute from "./routes/PublicRoute";
import HomeComponent from "./components/HomeComponent";
import LoginFormComponent from "./auth/LoginFormComponent";
import RegisterFormComponent from "./auth/RegisterFormComponent";
import ExamPapersComponent from "./components/ExamPapersComponent";
import ViewSelectedAnswerTHPT from "./components/ViewSelectedAnswerTHPT";
import ViewSelectedAnswerDGTD from "./components/ViewSelectedAnswerDGTD";
import ViewRankUsers from "./components/ViewRankUsers";
import AccountInfo from "./components/AccountInfo";
import ParticleBackground from "./ParticleBackground";
import ExecuteTest_THPT_Component from "./components/ExecuteTest_THPT_Component";
import ExecuteTest_TSA_Component from "./components/ExecuteTest_TSA_Component";
import ExecuteTest_HSA_Component from "./components/ExecuteTest_HSA_Component";

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route>
      <Route element={<PrivateRoute />}>
        <Route path="/" element={<HomeComponent />} />
        <Route path="/exam-papers" element={<ExamPapersComponent />} />
        <Route
          path="exam-papers/detail/THPT/:examId"
          element={<ExecuteTest_THPT_Component />}
        />
        <Route
          path="exam-papers/detail/TSA/:examId"
          element={<ExecuteTest_TSA_Component />}
        />
         <Route
          path="exam-papers/detail/HSA/:examId"
          element={<ExecuteTest_HSA_Component />}
        />
        <Route
          path="exam-papers/view-selected-answer-thpt/:examId"
          element={<ViewSelectedAnswerTHPT />}
        />
        <Route
          path="exam-papers/view-selected-answer-tsa/:examId"
          element={<ViewSelectedAnswerDGTD />}
        />
        <Route path="/user-ranks" element={<ViewRankUsers />} />
        <Route path="/account-info" element={<AccountInfo />} />
      </Route>
      <Route element={<PublicRoute />}>
        <Route path="/register" element={<RegisterFormComponent />} />
        <Route path="/login" element={<LoginFormComponent />} />
      </Route>
    </Route>
  )
);
function App() {
  return (
    <div className="App">
      {/* <FallingLeaves /> */}
      <ParticleBackground />
      <RouterProvider router={router} />
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </div>
  );
}

export default App;
