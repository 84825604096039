import React from "react";
import { Link } from "react-router-dom";
import MenuIcon from "@mui/icons-material/Menu";
import FaceRoundedIcon from "@mui/icons-material/FaceRounded";
import { Tooltip } from "@mui/material";
import AccountMenu from "./AccountMenu";

const NavbarComponent = (props) => {
  const {} = props;
  const jwt = localStorage.getItem("jwt")
    ? JSON.parse(localStorage.getItem("jwt"))
    : "";
  return (
    <nav
      className="navbar navbar-expand-lg navbar-dark ftco_navbar ftco-navbar-light site-navbar-target"
      id="ftco-navbar"
    >
      <div className="w-[100%] flex justify-between">
        <a className="navbar-brand potta text-[28px]" href="/">
          Pimath<sup>3.14</sup>
        </a>
        {/* <button
          className="navbar-toggler js-fh5co-nav-toggle fh5co-nav-toggle"
          type="button"
          data-toggle="collapse"
          data-target="#ftco-nav"
          aria-controls="ftco-nav"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <MenuIcon />
        </button> */}
        {/* <FaceRoundedIcon
          fontSize="large"
          className="lg:text-black text-white lg:hidden block"
        /> */}
        <AccountMenu className="lg:hidden block" />
        <div className="navbar-collapse lg:flex hidden" id="ftco-nav">
          <ul className="navbar-nav nav ml-auto items-center">
            <li className="nav-item">
              <a href="/" className="nav-link">
                <h4>Trang chủ</h4>
              </a>
            </li>
            <li className="nav-item">
              <a href="#about-section" className="nav-link">
                <h4>Giới thiệu</h4>
              </a>
            </li>
            <li className="nav-item">
              <a href="#chapter-section" className="nav-link">
                <h4>Khoá học của Pi</h4>
              </a>
            </li>
            <li className="nav-item">
              <a href="#testimonial-section" className="nav-link">
                <h4>Nhận xét</h4>
              </a>
            </li>
            {/* <li className="nav-item">
              <Link to="/home/mock-exam" className="nav-link">
                <span>Thi thử</span>
              </Link>
            </li> */}
            <li className="nav-item">
              <a href="#contact-section" className="nav-link">
                <h4>Liên hệ</h4>
              </a>
            </li>
            <AccountMenu />
            {/* <li className="nav-item">
              <a href="#projects-section" className="nav-link">
                <span>Sách của Pi</span>
              </a>
            </li> */}
            {/* <li className="nav-item">
              <a href="#author-section" className="nav-link">
                <span>Admin</span>
              </a>
            </li> */}
          </ul>
        </div>
      </div>
    </nav>
  );
};

const level = () => {
  return <div>hehee</div>;
};
export default NavbarComponent;
