import React, { useEffect, useState } from "react";
import FooterComponent from "../components/FooterComponent";
import { Link } from "react-router-dom";
import Paper from "@mui/material/Paper";
import InputBase from "@mui/material/InputBase";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import SearchIcon from "@mui/icons-material/Search";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import NavbarComponent from "./NavbarComponent";
import HeroWrapComponent from "../components/HelloWorldComponent";
import ExamCartComponent from "../components/ExamCartComponent";
import { getExams, searchExam } from "../services/MockExamService";
import { toast } from "react-toastify";
import LoadingComponent from "./LoadingComponent";

const ExamPapersComponent = (props) => {
 
  const [items, setItems] = useState([]);
  const [totalPages, setTotalPages] = useState(1);
  const [page, setPage] = useState(1); // Trang hiện tại
  const [querySearch, setQuerySearch] = useState("");
  const [loading, setLoading] = useState(false);

  const fetchItems = async (pageNumber) => {
    setLoading(true);
    try {
      const response = await getExams(pageNumber);
      if (
        response &&
        response.data &&
        response.data.data &&
        response.status === 200
      ) {
        setItems(response.data.data);
        setTotalPages(response.data.totalPages);
      }
    } catch (error) {
      const message = error?.response?.data?.message;
      toast.error(message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchItems(page);
  }, []);

  const handleChange = (event, value) => {
    setPage(value);
    fetchItems(value);
  };

  const handleSearchExam = async (pageNumber = 1) => {
    try {
      setLoading(true);
      const res = await searchExam(querySearch, pageNumber);
      if (res && res.data && res.status === 200) {
        setItems(res.data.data);
        setTotalPages(res.data.totalPages);
        setPage(res.data.currentPage);
      }
    } catch (error) {
      const message = error?.response?.data?.message;
      toast.error(message);
    } finally {
      setLoading(false);
    }
  };
  
  return (
    <>
      <div className="max-w-[1140px] m-auto">
        <NavbarComponent />
        <HeroWrapComponent />
      </div>
      <h2 className="font-bold text-center mb-[50px] mt-[50px] text-[40px]">
        Tổng Hợp Đề Thi Thử Nhóm Pi
      </h2>
      <div className="">
        <div className="flex justify-center">
          <Paper
            component="form"
            className="flex items-center justify-center mb-[40px] text-right md:w-[500px] w-[350px]"
            sx={{
              p: "2px 4px",
            }}
          >
            <IconButton sx={{ p: "10px" }} aria-label="menu">
              <MenuIcon />
            </IconButton>
            <InputBase
              sx={{ ml: 1, flex: 1 }}
              placeholder="Tìm kiếm bài thi"
              inputProps={{ "aria-label": "search google maps" }}
              onChange={(e) => setQuerySearch(e.target.value)}
            />
            <IconButton
              type="button"
              sx={{ p: "10px" }}
              aria-label="search"
              onClick={handleSearchExam}
            >
              <SearchIcon />
            </IconButton>
          </Paper>
        </div>
        <div className="flex justify-center">
          <div
            className={
              items.length < 3
                ? "flex flex-wrap justify-center"
                : "flex flex-wrap justify-center px-[5px] md:px-[20px] lg:px-[110px] xl:px-[20px] 2xl:px-[120px]"
            }
          >
            {loading ? (
              <LoadingComponent />
            ) : (
              items.map((item, key) => {
                return (
                  <div
                    className={
                      items.length < 3
                        ? "w-full"
                        : "xl:w-1/3 md:w-1/2 w-full p-2"
                    }
                    key={key}
                  >
                    <div className="flex justify-center">
                      <ExamCartComponent exam={item} loading={loading} />
                    </div>
                  </div>
                );
              })
            )}
          </div>
        </div>
      </div>
      {!loading && (
        <Stack spacing={2} sx={{ margin: "40px 0px" }}>
          <Pagination
            count={totalPages}
            page={page}
            onChange={handleChange}
            color="primary"
            sx={{ display: "flex", justifyContent: "center" }}
          />
        </Stack>
      )}

      <FooterComponent />
    </>
  );
};

export default ExamPapersComponent;
