import React, { useEffect, useState } from "react";
import NavbarComponent from "./NavbarComponent";
import HeroWrapComponent from "./HelloWorldComponent";
import FooterComponent from "./FooterComponent";
import { useParams } from "react-router-dom";
import {
  checkSeeDetailedAnswers,
  getExamById,
  getExamResultbyId,
} from "../services/MockExamService";
import { toast } from "react-toastify";
import LoadingComponent from "./LoadingComponent";
import { Button, Tooltip } from "@mui/material";
import QuizIcon from "@mui/icons-material/Quiz";

function ViewSelectedAnswerTHPT() {
  const { examId } = useParams();
  const [loading, setLoading] = useState(false);
  const [examData, setExamData] = useState(null);
  const [userAnswers, setUserAnswers] = useState(null);
  const [isCheckSeeDetailedAnswers, setIsCheckSeeDetailedAnswers] =
    useState(false);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const response = await getExamResultbyId(examId);
        if (
          response &&
          response.data &&
          response.data.data &&
          response.status === 200
        ) {
          let { data } = response.data;
          setUserAnswers(data.userAnswers);
        }
      } catch (error) {
        const message = error?.response?.data?.message;
        toast.error(message);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const response = await checkSeeDetailedAnswers(examId);
        if (
          response &&
          response.data &&
          response.data.data &&
          response.status === 200
        ) {
          let { data } = response.data;
          if (data) setIsCheckSeeDetailedAnswers(data);
        }
      } catch (error) {
        const message = error?.response?.data?.message;
        toast.error(message);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const response = await getExamById(examId);
        if (
          response &&
          response.data &&
          response.data.data &&
          response.status === 200
        ) {
          let { data } = response.data;
          setExamData(data);
        }
      } catch (error) {
        const message = error?.response?.data?.message;
        toast.error(message);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const handleSeeDetailedAnswers = () => {
    if (!examData?.link_answer) {
      toast.error("Đáp án chưa được biên soạn bởi Admin, chờ nhé!!");
      return;
    }
    window.open(examData?.link_answer, "_blank", "noopener,noreferrer");
  };

  if (loading) {
    return <LoadingComponent />;
  }

  return (
    <div>
      <div className="max-w-[1140px] m-auto">
        <NavbarComponent />
        <HeroWrapComponent />
      </div>
      <h1 className="text-center text-[30px] mb-10 mt-10">
        ĐÁP ÁN ĐÃ CHỌN CỦA BẠN
      </h1>
      <div className="text-center">
        <Tooltip
          title={`Bài thi phải trên 6 điểm (TK-TH), 5 điểm (TK-VIP) và nộp bài trong vòng 30 ngày kể từ lúc tổ chức thi.`}
          placement="top"
        >
          <span>
            {" "}
            <Button
              className="mr-5"
              variant="contained"
              startIcon={<QuizIcon />}
              size="large"
              disabled={isCheckSeeDetailedAnswers === false}
              onClick={handleSeeDetailedAnswers}
            >
              Xem đáp án chi tiết
            </Button>
          </span>
        </Tooltip>
      </div>
      <div className="overflow-auto mt-[40px] mb-10 md:max-w-[1200px] max-w-[400px] m-auto">
        <table className="table-view-answer styled-table w-[1200px] m-auto">
          <tr>
            <th rowspan="2">PHẦN I</th>
            <th>CÂU</th>
            <th>1</th>
            <th>2</th>
            <th>3</th>
            <th>4</th>
            <th>5</th>
            <th>6</th>
            <th>7</th>
            <th>8</th>
            <th>9</th>
            <th>10</th>
            <th>11</th>
            <th>12</th>
          </tr>
          <tr>
            <td className="tr-select">CHỌN</td>
            <td>{userAnswers?.partIAnswer["Câu 1"]} </td>
            <td>{userAnswers?.partIAnswer["Câu 2"]}</td>
            <td>{userAnswers?.partIAnswer["Câu 3"]}</td>
            <td>{userAnswers?.partIAnswer["Câu 4"]}</td>
            <td>{userAnswers?.partIAnswer["Câu 5"]}</td>
            <td>{userAnswers?.partIAnswer["Câu 6"]}</td>
            <td>{userAnswers?.partIAnswer["Câu 7"]}</td>
            <td>{userAnswers?.partIAnswer["Câu 8"]}</td>
            <td>{userAnswers?.partIAnswer["Câu 9"]}</td>
            <td>{userAnswers?.partIAnswer["Câu 10"]}</td>
            <td>{userAnswers?.partIAnswer["Câu 11"]}</td>
            <td>{userAnswers?.partIAnswer["Câu 12"]}</td>
          </tr>
          <tr className="tr-phan2">
            <th rowspan="2">PHẦN II</th>
            <td>CÂU</td>
            <td>1</td>
            <td>2</td>
            <td>3</td>
            <td>4</td>
          </tr>
          <tr>
            <td className="tr-select">CHỌN</td>
            <td>
              <div>a) {userAnswers?.partIIAnswer["Câu 1"]["a"]}</div>
              <div>b) {userAnswers?.partIIAnswer["Câu 1"]["b"]}</div>
              <div>c) {userAnswers?.partIIAnswer["Câu 1"]["c"]}</div>
              <div>d) {userAnswers?.partIIAnswer["Câu 1"]["d"]}</div>
            </td>
            <td>
              <div>a) {userAnswers?.partIIAnswer["Câu 2"]["a"]}</div>
              <div>b) {userAnswers?.partIIAnswer["Câu 2"]["b"]}</div>
              <div>c) {userAnswers?.partIIAnswer["Câu 2"]["c"]}</div>
              <div>d) {userAnswers?.partIIAnswer["Câu 2"]["d"]}</div>
            </td>
            <td>
              <div>a) {userAnswers?.partIIAnswer["Câu 3"]["a"]}</div>
              <div>b) {userAnswers?.partIIAnswer["Câu 3"]["b"]}</div>
              <div>c) {userAnswers?.partIIAnswer["Câu 3"]["c"]}</div>
              <div>d) {userAnswers?.partIIAnswer["Câu 3"]["d"]}</div>
            </td>
            <td>
              <div>a) {userAnswers?.partIIAnswer["Câu 4"]["a"]}</div>
              <div>b) {userAnswers?.partIIAnswer["Câu 4"]["b"]}</div>
              <div>c) {userAnswers?.partIIAnswer["Câu 4"]["c"]}</div>
              <div>d) {userAnswers?.partIIAnswer["Câu 4"]["d"]}</div>
            </td>
          </tr>
          <tr className="tr-phan3">
            <th rowspan="2">PHẦN III</th>
            <td>CÂU</td>
            <td>1</td>
            <td>2</td>
            <td>3</td>
            <td>4</td>
            <td>5</td>
            <td>6</td>
          </tr>
          <tr>
            <td className="tr-select">CHỌN</td>
            <td>{userAnswers?.partIIIAnswer["Câu 1"]}</td>
            <td>{userAnswers?.partIIIAnswer["Câu 2"]}</td>
            <td>{userAnswers?.partIIIAnswer["Câu 3"]}</td>
            <td>{userAnswers?.partIIIAnswer["Câu 4"]}</td>
            <td>{userAnswers?.partIIIAnswer["Câu 5"]}</td>
            <td>{userAnswers?.partIIIAnswer["Câu 6"]}</td>
          </tr>
        </table>
      </div>
      <FooterComponent />
    </div>
  );
}

export default ViewSelectedAnswerTHPT;
