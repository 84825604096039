import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import {
  getExamById,
  getExamResultbyId,
  getExamResults,
  submitAssignmentDGTD,
} from "../services/MockExamService.js";
import NavbarComponent from "./NavbarComponent.js";
import HeroWrapComponent from "./HelloWorldComponent.js";
import FooterComponent from "./FooterComponent.js";
import ExamContainer from "./ExamContainer.js";
import ExamInfo from "./ExamInfo.js";
import LoadingComponent from "./LoadingComponent.js";

const ExecuteTest_TSA_Component = (props) => {
  const {} = props;
  const { examId } = useParams();
  const navigate = useNavigate();
  const [examResult, setExamResult] = useState(null);
  const [examResults, setExamResults] = useState(null);
  const [examData, setExamData] = useState(null);
  const [countdownTime, setCountdownTime] = useState(0);
  const [isCompleted, setIsCompleted] = useState(false);
  const [isStarted, setIsStarted] = useState(false);
  const [userAnswers, setUserAnswers] = useState(null);
  const [questions, setQuestions] = useState(null);
  const [loading, setLoading] = useState(true);

  const onDragEnd = (result) => {
    const { destination, source } = result;
    if (!destination) return;

    const question = questions.find((e) => e.question === source.droppableId); // Giả sử chỉ có một câu hỏi, bạn có thể điều chỉnh để hỗ trợ nhiều câu hỏi

    const draggedItem = question.items[source.index];

    // Nếu thả vào ô trống (slot)
    if (destination.droppableId.startsWith("slot")) {
      const slotKey = destination.droppableId;
      const previousItemInSlot = question.answers[slotKey];

      setQuestions((prevQuestions) => {
        const updatedQuestions = [...prevQuestions];
        const oldQuestion = updatedQuestions.find(
          (e) => e.question === source.droppableId
        );
        // Cập nhật ô trống với item được kéo thả vào
        oldQuestion.answers[slotKey] = draggedItem;

        // Xóa item khỏi danh sách items
        oldQuestion.items = oldQuestion.items.filter(
          (item) => item.id !== draggedItem.id
        );

        // Nếu có item trước đó trong ô trống, đưa nó trở lại danh sách items
        if (previousItemInSlot) {
          oldQuestion.items.push(previousItemInSlot);
        }
        setUserAnswers((prevAnswers) => {
          const newAnswer = {
            ...prevAnswers,
            [source.droppableId]: {
              ...prevAnswers[source.droppableId],
              [slotKey]: draggedItem.id,
            },
          };
          localStorage.setItem(
            `userAnswers_${examId}`,
            JSON.stringify(newAnswer)
          );
          return newAnswer;
        });
        localStorage.setItem(
          `questions_${examId}`,
          JSON.stringify(updatedQuestions)
        );
        return updatedQuestions;
      });
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getExamResultbyId(examId, "TSA");
        if (
          response &&
          response.data &&
          response.data.data &&
          response.status === 200
        ) {
          let { data } = response.data;
          setExamResult(data);
          setIsStarted(data?.isStarted);
          setIsCompleted(data?.isCompleted);
        }
      } catch (error) {
        const message = error?.response?.data?.message;
        toast.error(message);
      } finally {
        // setLoading(false);
      }
    };

    fetchData();
  }, [examId, isCompleted]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getExamResults(examId, "TSA");
        if (
          response &&
          response.data &&
          response.data.data &&
          response.status === 200
        ) {
          const { data } = response.data;
          setExamResults(data);
        }
      } catch (error) {
        const message = error?.response?.data?.message;
        toast.error(message);
      } finally {
        // setLoading(false);
      }
    };

    fetchData();
  }, [examId, isCompleted]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getExamById(examId);
        if (
          response &&
          response.data &&
          response.data.data &&
          response.status === 200
        ) {
          let { data } = response.data;
          const localQuestions = localStorage.getItem(`questions_${examId}`);
          if (localQuestions) {
            setQuestions(JSON.parse(localQuestions));
          } else {
            setQuestions(data?.questions);
          }

          const localUserAnswers = localStorage.getItem(
            `userAnswers_${examId}`
          );
          if (!localUserAnswers) {
            setUserAnswers((prevAnswers) => {
              let newPrevAnswers = { ...prevAnswers };
              Array.isArray(data?.questions) && data?.questions.forEach(
                (q) => {
                  if (q.type === "TN") {
                    newPrevAnswers = { ...newPrevAnswers, [q.question]: "" };
                  } else if (q.type === "DS") {
                    newPrevAnswers = {
                      ...newPrevAnswers,
                      [q.question]: { a: "", b: "", c: "", d: "" },
                    };
                  } else if (q.type === "KT") {
                    newPrevAnswers = {
                      ...newPrevAnswers,
                      [q.question]: {
                        slot1: "",
                        slot2: "",
                        slot3: "",
                        slot4: "",
                      },
                    };
                  } else if (q.type === "TLN_M") {
                    newPrevAnswers = {
                      ...newPrevAnswers,
                      [q.question]: { "1.": "", "2.": "", "3.": "", "4.": "" },
                    };
                  } else if (q.type === "TLN") {
                    newPrevAnswers = {
                      ...newPrevAnswers,
                      [q.question]: null,
                    };
                  } else {
                    toast.error(`Câu hỏi không hợp lệ: ${q?.question}`);
                  }
                }
              );
              return newPrevAnswers;
            });
          } else {
            setUserAnswers(JSON.parse(localUserAnswers));
          }

          setExamData(data);
          const savedCountdownTime = localStorage.getItem(
            `countdownTime_${examId}`
          );
          if (!savedCountdownTime) {
            setCountdownTime(parseInt(data.time * 60 * 1000));
          } else {
            setCountdownTime(parseInt(JSON.parse(savedCountdownTime)));
          }
        }
      } catch (error) {
        const message = error?.response?.data?.message;
        toast.error(message);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [examId, isCompleted]);

  const handleChangeUserAnswersTN = (event) => {
    const { name, value } = event.target;

    // Cập nhật giá trị của TextField trong state answers
    setUserAnswers((prevAnswers) => {
      const newAnswer = { ...prevAnswers, [name]: value };
      localStorage.setItem(`userAnswers_${examId}`, JSON.stringify(newAnswer));
      return newAnswer;
    });
  };

  const handleChangeUserAnswersDS = (question, option, isCorrect) => {
    setUserAnswers((prevAnswers) => {
      const newAnswer = {
        ...prevAnswers,
        [question]: {
          ...prevAnswers[question],
          [option]: isCorrect ? "D" : "S",
        },
      };
      localStorage.setItem(`userAnswers_${examId}`, JSON.stringify(newAnswer));
      return newAnswer;
    });
  };

  const handleChangeUserAnswersTLN = (event) => {
    const { name, value } = event.target;
    const numericValue = value === "0" ? "0" : parseFloat(value); // Chuyển giá trị sang số hoặc giữ chuỗi rỗng

    setUserAnswers((prevAnswers) => {
      const newAnswer = {
        ...prevAnswers,
        [name]: numericValue, // Lưu giá trị dưới dạng số
      };
      localStorage.setItem(`userAnswers_${examId}`, JSON.stringify(newAnswer));
      return newAnswer;
    });
  };

  const handleChangeUserAnswersTLN_M = (event) => {
    const { name, value } = event.target;
    const numericValue = value === "0" ? "0" : parseFloat(value); // Chuyển giá trị sang số hoặc giữ chuỗi rỗng
    const temp = name.split("-");
    if (name.includes("1.")) {
      setUserAnswers((prevAnswers) => {
        const newAnswer = {
          ...prevAnswers,
          [temp[0]]: { ...prevAnswers[temp[0]], "1.": numericValue }, // Lưu giá trị dưới dạng số
        };
        localStorage.setItem(
          `userAnswers_${examId}`,
          JSON.stringify(newAnswer)
        );
        return newAnswer;
      });
    } else if (name.includes("2.")) {
      setUserAnswers((prevAnswers) => {
        const newAnswer = {
          ...prevAnswers,
          [temp[0]]: { ...prevAnswers[temp[0]], "2.": numericValue }, // Lưu giá trị dưới dạng số
        };
        localStorage.setItem(
          `userAnswers_${examId}`,
          JSON.stringify(newAnswer)
        );
        return newAnswer;
      });
    } else if (name.includes("3.")) {
      setUserAnswers((prevAnswers) => {
        const newAnswer = {
          ...prevAnswers,
          [temp[0]]: { ...prevAnswers[temp[0]], "3.": numericValue }, // Lưu giá trị dưới dạng số
        };
        localStorage.setItem(
          `userAnswers_${examId}`,
          JSON.stringify(newAnswer)
        );
        return newAnswer;
      });
    } else if (name.includes("4.")) {
      setUserAnswers((prevAnswers) => {
        const newAnswer = {
          ...prevAnswers,
          [temp[0]]: { ...prevAnswers[temp[0]], "4.": numericValue }, // Lưu giá trị dưới dạng số
        };
        localStorage.setItem(
          `userAnswers_${examId}`,
          JSON.stringify(newAnswer)
        );
        return newAnswer;
      });
    }
  };

  const renderer = ({ hours, minutes, seconds, completed }) => {
    setCountdownTime(
      hours * 60 * 60 * 1000 + minutes * 60 * 1000 + seconds * 1000
    );
    localStorage.setItem(
      `countdownTime_${examId}`,
      JSON.stringify(
        hours * 60 * 60 * 1000 + minutes * 60 * 1000 + seconds * 1000
      )
    );
    if (completed) {
      setIsCompleted(true);
      setCountdownTime(0);
      localStorage.removeItem(`countdownTime_${examId}`);
      localStorage.removeItem(`userAnswers_${examId}`);
      handleSubmitExam();
    } else {
      return (
        <h5 style={{ marginLeft: "5px", color: "rgb(220, 53, 69)" }}>
          {" "}
          {hours}:{minutes}:{seconds}
        </h5>
      );
    }
  };

  const handleStartExam = async () => {
    setLoading(true);
    try {
      const results = await submitAssignmentDGTD({
        examId: examId,
        userAnswers,
      });
      if (
        results &&
        results.data &&
        results.data.data &&
        results.status === 200
      ) {
        setIsStarted(true);
        setIsCompleted(false);
        setExamResult(results.data.data);
        toast.success(results.data?.message);
      }
    } catch (error) {
      const message = error?.response?.data?.message;
      toast.error(message);
    } finally {
      setLoading(false);
    }
  };
  const handleSubmitExam = async () => {
    setLoading(true);
    try {
      let newUserAnswers = {};
      Object.keys(userAnswers).forEach((question) => {
        if (userAnswers[question] === "object") {
          for (const key in userAnswers[question]) {
            newUserAnswers = {
              ...newUserAnswers,
              [question]:
                userAnswers[question][key] === "0"
                  ? { ...userAnswers[question], [key]: 0 }
                  : userAnswers[key],
            };
          }
        } else {
          newUserAnswers = {
            ...newUserAnswers,
            [question]:
              userAnswers[question] === "0" ? 0 : userAnswers[question],
          };
        }
      });
      const results = await submitAssignmentDGTD({
        examId: examId,
        userAnswers: newUserAnswers,
        examCompledTime: countdownTime,
      });

      if (results && results.data && results.status === 200) {
        localStorage.removeItem(`countdownTime_${examId}`);
        setCountdownTime(0);
        setIsStarted(true);
        setIsCompleted(true);
        toast.success(results.data?.message);
      }
    } catch (error) {
      const message = error?.response?.data?.message;
      toast.error(message);
    } finally {
      setLoading(false);
    }
  };

  const handleStartRetestExam = async () => {
    setLoading(true);
    try {
      const results = await submitAssignmentDGTD({
        examId: examId,
        userAnswers,
        isReTest: true,
      });
      if (
        results &&
        results.data &&
        results.data.data &&
        results.status === 200
      ) {
        setIsStarted(true);
        setIsCompleted(false);
        setExamResult(results.data.data);
        toast.success(results.data?.message);
      }
    } catch (error) {
      const message = error?.response?.data?.message;
      toast.error(message);
    } finally {
      setLoading(false);
    }
  };

  const handleViewSelectedAnswerExam = () => {
    navigate(`/exam-papers/view-selected-answer-tsa/${examId}`);
  };

  if (loading) {
    return <LoadingComponent />;
  }

  return (
    <>
      <div className="max-w-[1140px] m-auto">
        <NavbarComponent />
        <HeroWrapComponent />
      </div>
      <section
        className="ftco-section ftco-no-pb ftco-no-pt test-online"
        id="chapter-section"
      >
        <div className="Exam">
          <ExamInfo
            examData={examData}
            isStarted={isStarted}
            handleStartExam={handleStartExam}
            examResult={examResult}
          />
          <ExamContainer
            isStarted={isStarted}
            isCompleted={isCompleted}
            examData={examData}
            questions={questions}
            userAnswers={userAnswers}
            handleChangeUserAnswersTN={handleChangeUserAnswersTN}
            handleChangeUserAnswersDS={handleChangeUserAnswersDS}
            handleChangeUserAnswersTLN={handleChangeUserAnswersTLN}
            handleChangeUserAnswersTLN_M={handleChangeUserAnswersTLN_M}
            onDragEnd={onDragEnd}
            countdownTime={countdownTime}
            renderer={renderer}
            handleSubmitExam={handleSubmitExam}
            examResult={examResult}
            handleStartRetestExam={handleStartRetestExam}
            handleViewSelectedAnswerExam={handleViewSelectedAnswerExam}
            examResults={examResults}
          />
        </div>
      </section>
      <FooterComponent />
    </>
  );
};

export default ExecuteTest_TSA_Component;
