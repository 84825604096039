import React from "react";
import CameraIcon from "@mui/icons-material/Camera";
import { Button } from "@mui/material";

const StartExamButton = ({ isStarted, examResult, handleStartExam }) => {
  return (
    <>
      {!isStarted && (
        <div className="flex justify-center mt-10">
          <Button
            variant="contained"
            startIcon={<CameraIcon />}
            size="large"
            onClick={handleStartExam}
            disabled={examResult?.maximumNumberOfExams <= 0}
          >
            Bắt đầu thi thử
          </Button>
        </div>
      )}
    </>
  );
};

export default StartExamButton;
