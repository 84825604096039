import React from "react";

const CounterSectionComponent = (props) => {
  const {} = props;

  return (
    <section
      className="ftco-section ftco-no-pt ftco-no-pb ftco-counter img"
      id="section-counter"
    >
      <div className="container">
        <div className="row d-md-flex align-items-center align-items-stretch">
          <div className="col-md d-flex justify-content-center counter-wrap ftco-animate">
            <div className="block-18 bg-white">
              <div className="text">
                <strong className="number" data-number={24260}>
                  24260
                </strong>
                <span>Thành viên</span>
              </div>
            </div>
          </div>
          <div className="col-md d-flex justify-content-center counter-wrap ftco-animate">
            <div className="block-18 bg-white">
              <div className="text">
                <strong className="number" data-number={12}>
                  4
                </strong>
                <span>Khoá học</span>
              </div>
            </div>
          </div>
          <div className="col-md d-flex justify-content-center counter-wrap ftco-animate">
            <div className="block-18 bg-white">
              <div className="text">
                <strong className="number" data-number={10}>
                  5
                </strong>
                <span>Admin</span>
              </div>
            </div>
          </div>
          <div className="col-md d-flex justify-content-center counter-wrap ftco-animate">
            <div className="block-18 bg-white">
              <div className="text">
                <strong className="number" data-number={2}>
                  3
                </strong>
                <span>Sách</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default CounterSectionComponent;
