import React, { useEffect, useState } from "react";
import NavbarComponent from "./NavbarComponent";
import HeroWrapComponent from "./HelloWorldComponent";
import FooterComponent from "./FooterComponent";
import { useParams } from "react-router-dom";
import {
  checkSeeDetailedAnswers,
  getExamById,
  getExamResultbyId,
} from "../services/MockExamService";
import { toast } from "react-toastify";
import LoadingComponent from "./LoadingComponent";
import { Button, Tooltip } from "@mui/material";
import QuizIcon from "@mui/icons-material/Quiz";
import ReactJson from "react-json-view";

function ViewSelectedAnswer() {
  const { examId } = useParams();
  const [loading, setLoading] = useState(false);
  const [examData, setExamData] = useState(null);
  const [userAnswers, setUserAnswers] = useState(null);
  const [isCheckSeeDetailedAnswers, setIsCheckSeeDetailedAnswers] =
    useState(false);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const response = await getExamResultbyId(examId);
        if (
          response &&
          response.data &&
          response.data.data &&
          response.status === 200
        ) {
          let { data } = response.data;
          setUserAnswers(data.userAnswers);
        }
      } catch (error) {
        const message = error?.response?.data?.message;
        toast.error(message);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const response = await checkSeeDetailedAnswers(examId);
        if (
          response &&
          response.data &&
          response.data.data &&
          response.status === 200
        ) {
          let { data } = response.data;
          if (data) setIsCheckSeeDetailedAnswers(data);
        }
      } catch (error) {
        const message = error?.response?.data?.message;
        toast.error(message);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const response = await getExamById(examId);
        if (
          response &&
          response.data &&
          response.data.data &&
          response.status === 200
        ) {
          let { data } = response.data;
          setExamData(data);
        }
      } catch (error) {
        const message = error?.response?.data?.message;
        toast.error(message);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const handleSeeDetailedAnswers = () => {
    if (!examData?.link_answer) {
      toast.error("Đáp án chưa được biên soạn bởi Admin, chờ nhé!!");
      return;
    }
    window.open(examData?.link_answer, "_blank", "noopener,noreferrer");
  };

  if (loading) {
    return <LoadingComponent />;
  }

  return (
    <div>
      <div className="max-w-[1140px] m-auto mb-10">
        <NavbarComponent />
        <HeroWrapComponent />
      </div>
      <div className="text-center">
        <Tooltip
          title={`Bài thi phải trên 6 điểm (TK-TH), 5 điểm (TK-VIP) và nộp bài trong vòng 30 ngày kể từ lúc tổ chức thi.`}
          placement="top"
        >
          <span>
            {" "}
            <Button
              className=""
              variant="contained"
              startIcon={<QuizIcon />}
              size="large"
              disabled={isCheckSeeDetailedAnswers === false}
              onClick={handleSeeDetailedAnswers}
            >
              Xem đáp án chi tiết
            </Button>
          </span>
        </Tooltip>
      </div>

      <div className="flex justify-center mt-[40px] mb-10 anwser-json">
        <ReactJson
          src={userAnswers}
          // theme="monokai"
          collapsed={false}
          name="NHỮNG ĐÁP ÁN MÀ BAN ĐÃ CHỌN"
          displayDataTypes={false}
        />
      </div>
      <FooterComponent />
    </div>
  );
}

export default ViewSelectedAnswer;
