import React from "react";
import XIcon from "@mui/icons-material/X";
import FacebookOutlinedIcon from "@mui/icons-material/FacebookOutlined";
import GroupIcon from "@mui/icons-material/Group";
import PhoneIcon from "@mui/icons-material/Phone";
import AddLocationAltIcon from "@mui/icons-material/AddLocationAlt";
import EmailIcon from "@mui/icons-material/Email";

const FooterComponent = (props) => {
  const {} = props;

  return (
    <footer className="ftco-footer ftco-section">
      <div className="container">
        <div className="row">
          <div className="col-md">
            <div className="ftco-footer-widget mb-4">
              <h2 className="ftco-heading-2 pacifico">Giới thiệu</h2>
              <p className="text-white">
                Nhóm Pimath là nơi giao lưu của các thần đồng toán học, chúa tể
                của những con số, kẻ hủy diệt phương trình tương lai
              </p>
              <ul className="ftco-footer-social list-unstyled float-md-left float-lft mt-5">
                <li className="ftco-animate">
                  <a
                    href="#1"
                    className="text-white flex justify-center items-center"
                  >
                    <XIcon />
                  </a>
                </li>
                <li className="ftco-animate">
                  <a
                    href="https://www.facebook.com/tuanpham.pmt"
                    className="text-white flex justify-center items-center"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FacebookOutlinedIcon />
                  </a>
                </li>
                <li className="ftco-animate">
                  <a
                    href="https://www.facebook.com/groups/pimath"
                    className="text-white flex justify-center items-center"
                  >
                    <GroupIcon />
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-md">
            <div className="ftco-footer-widget mb-4 ml-md-4">
              <h2 className="ftco-heading-2 pacifico">Về Nhóm Pi</h2>
              <ul className="list-unstyled">
                <li>
                  <a href="#pi" className="text-white text-[17px]">
                    Liên hệ
                  </a>
                </li>
                <li>
                  <a href="#pi" className="text-white  text-[18px]">
                    Khoá học
                  </a>
                </li>
                <li>
                  <a href="#pi" className="text-white text-[17px]">
                    Giới thiệu
                  </a>
                </li>
                <li>
                  <a href="#pi" className="text-white text-[17px]">
                    Chính sách miễn giảm học phí
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-md">
            <div className="ftco-footer-widget mb-4">
              <h2 className="ftco-heading-2 pacifico">Hỗ trợ</h2>
              <ul className="list-unstyled">
                <li>
                  <a href="#pi" className="text-white text-[17px]">
                    Câu hỏi bài tập
                  </a>
                </li>
                <li>
                  <a href="#pi" className="text-white text-[17px]">
                    Thi thử online
                  </a>
                </li>
                <li>
                  <a href="#pi" className="text-white text-[17px]">
                    Cách đăng ký khoá học
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-md">
            <div className="ftco-footer-widget mb-4">
              <h2 className="ftco-heading-2 pacifico">Liên hệ</h2>
              <div className="block-23 mb-3">
                <ul>
                  <li>
                    <AddLocationAltIcon className="icon" />
                    <span className="text text-white sign">Đà nẵng</span>
                  </li>
                  <li>
                    <PhoneIcon className="icon" />
                    <span className="text text-white sign">0777561933</span>
                  </li>
                  <li>
                    <EmailIcon className="icon" />
                    <span className="text text-white sign">
                      tuanpham31798@gmail.com
                    </span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12 text-center">
            <p>
              {/* Link back to Colorlib can't be removed. Template is licensed under CC BY 3.0. */}
              Copyright © All rights reserved | This template is made with{" "}
              <i className="fa fa-heart" aria-hidden="true" /> by{" "}
              <a href="https://colorlib.com" target="_blank" rel="noreferrer">
                Pimath
              </a>
              {/* Link back to Colorlib can't be removed. Template is licensed under CC BY 3.0. */}
            </p>
          </div>
        </div>
      </div>
    </footer>
  );
};

FooterComponent.propTypes = {};

export default FooterComponent;
